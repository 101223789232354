import React from 'react';
import {
  FaFacebookF,
  FaInstagram,
  FaTiktok,
  FaLinkedinIn,
  FaGithub,
} from 'react-icons/fa';
import { MdOutlineEmail, MdDateRange, MdPhoneIphone } from 'react-icons/md';
import { BiMap } from 'react-icons/bi';

const Header = () => {
  return (
    <div className="bg-white-bg rounded-[2rem] xl:p-[30px] lg:p-[25px] w-ful p-[20px]">
      <div className="lg:grid grid-cols-2 flex items-center flex-col relative">
        {/* Left */}
        <div className="flex items-center justify-start lg:mr-[30px] lg:mt-0 mt-[76px]">
          <div className="">
            <img
              src="/assets/images/other/avatar5.jpg"
              alt=""
              className="z-10 h-[160px] aspect-square rounded-[1.5rem] object-cover shadow-lg absolute top-[-100px] left-1/2 -translate-x-1/2 lg:relative lg:top-auto"
            />
          </div>

          <div className="h-full flex flex-col items-center mb-10 lg:items-start lg:mb-0 lg:ml-6">
            <h1 className="lg:text-[30px] md:text-[22px] text-[20px] text-center text-primary font-bold">
              I'm Phuong Nam Nguyen
            </h1>
            <div className="typed-animation w-fit">
              <h3 className="typed-out text-[16px] text-secondary font-normal">
                Software Engineer
              </h3>
            </div>

            {/* Social */}
            <ul className="flex items-center mt-3 lg:mt-10">
              <li className="relative social">
                <a
                  href="https://www.facebook.com/profile.php?id=100010348932056"
                  target="_blank"
                  rel="noreferrer"
                  className="relative block p-[7px] mx-2 rounded-full text-primary bg-white shadow-lg cursor-pointer hover:bg-facebook hover:text-white transition-all duration-150 sm:p-[10px]"
                >
                  <FaFacebookF className="text-[20px] leading-[20px]" />
                </a>
                <div className="tooltip">Facebook</div>
              </li>
              <li className="relative social">
                <a
                  href="https://www.instagram.com/nblue151/"
                  target="_blank"
                  rel="noreferrer"
                  className="relative block p-[7px] mx-2 rounded-full text-primary bg-white shadow-lg cursor-pointer hover:bg-instagram hover:text-white transition-all duration-150 sm:p-[10px]"
                >
                  <FaInstagram className="text-[20px] leading-[20px]" />
                </a>
                <div className="tooltip">Instagram</div>
              </li>
              <li className="relative social">
                <a
                  href="https://www.tiktok.com/@npnx151"
                  target="_blank"
                  rel="noreferrer"
                  className="relative block p-[7px] mx-2 rounded-full text-primary bg-white shadow-lg cursor-pointer hover:bg-tiktok hover:text-white transition-all duration-150 sm:p-[10px]"
                >
                  <FaTiktok className="text-[20px] leading-[20px]" />
                </a>
                <div className="tooltip">Tiktok</div>
              </li>
              <li className="relative social">
                <a
                  href="https://www.linkedin.com/in/ph%C6%B0%C6%A1ng-nam-nguy%E1%BB%85n-b58bbb23a/"
                  target="_blank"
                  rel="noreferrer"
                  className="relative block p-[7px] mx-2 rounded-full text-primary bg-white shadow-lg cursor-pointer hover:bg-linkin hover:text-white transition-all duration-150 sm:p-[10px]"
                >
                  <FaLinkedinIn className="text-[20px] leading-[20px]" />
                </a>
                <div className="tooltip">Linkedin</div>
              </li>
              <li className="relative social">
                <a
                  href="https://github.com/NBlue"
                  target="_blank"
                  rel="noreferrer"
                  className="relative block p-[7px] mx-2 rounded-full text-primary bg-white shadow-lg cursor-pointer hover:bg-github hover:text-white transition-all duration-150 sm:p-[10px]"
                >
                  <FaGithub className="text-[20px] leading-[20px]" />
                </a>
                <div className="tooltip">Github</div>
              </li>
            </ul>
          </div>
        </div>

        <span className="hidden lg:block absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-full w-[1px] bg-gray-default"></span>

        {/* Right */}
        <div className="flex items-center ml-0 lg:ml-[70px]">
          <ul className="grid grid-cols-1 gap-x-[40px] gap-y-3 sm:grid-cols-2 sm:gap-y-[20px]">
            <li className="">
              <div className="flex items-center">
                <MdOutlineEmail className="text-lg sm:text-base lg:text-lg text-secondary mr-[2px]" />
                <h6 className="text-base sm:text-sm lg:text-base text-secondary font-medium uppercase">
                  Email
                </h6>
              </div>
              <p className="text-base sm:text-sm lg:text-base leading-[16px] text-primary font-normal ml-1">
                nguyennam.job@gmail.com
              </p>
            </li>
            <li className="">
              <div className="flex items-center">
                <MdDateRange className="text-lg sm:text-base lg:text-lg text-secondary mr-[2px]" />
                <h6 className="text-base sm:text-sm lg:text-base text-secondary font-medium uppercase">
                  Date
                </h6>
              </div>
              <p className="text-base sm:text-sm lg:text-base leading-[16px] text-primary font-normal ml-1">
                01/05/2001
              </p>
            </li>
            <li className="">
              <div className="flex items-center">
                <MdPhoneIphone className="text-lg sm:text-base lg:text-lg text-secondary mr-[2px]" />
                <h6 className="text-base sm:text-sm lg:text-base text-secondary font-medium uppercase">
                  Phone
                </h6>
              </div>
              <p className="text-base sm:text-sm lg:text-base leading-[16px] text-primary font-normal ml-1">
                0867764600
              </p>
            </li>
            <li className="">
              <div className="flex items-center">
                <BiMap className="text-lg sm:text-base lg:text-lg text-secondary mr-[2px]" />
                <h6 className="text-base sm:text-sm lg:text-base text-secondary font-medium uppercase">
                  Address
                </h6>
              </div>
              <p className="text-base sm:text-sm lg:text-base leading-[16px] text-primary font-normal ml-1">
                Hai Hau, Nam Dinh, Vietnam
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
