import React, { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import StickyBox from 'react-sticky-box';
// import { LordIcon } from '../../lordicon';
import Circle from './components/Circle';
import Header from './components/Header';
import Sidebar from './components/Sidebar';

// Scroll to top when tranform page
const ScrollToTop = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
};

const DefaultLayout = ({ children }) => {
    return (
        <ScrollToTop>
            <div className="w-full bg-primary-background relative ">
                {/* <Canvas /> */}

                {/* Layout */}
                <div className="relative z-10 py-[100px] mx-3 xl:w-[1200px] lg:w-[1020px] md:w-[700px] md:mx-auto sm:w-auto">
                    <Header />
                    <div className="w-full mt-[30px] lg:grid lg:grid-cols-[1fr_5fr] xl:grid-cols-[1fr_6fr] lg:gap-[20px]">
                        <div className="hidden lg:block">
                            <StickyBox offsetTop={30} offsetBottom={30}>
                                <Sidebar />
                            </StickyBox>
                        </div>
                        <div className="bg-white-bg rounded-[2rem] p-[20px] sm:p-[25px] lg:p-[30px]">{children}</div>
                        <div className="block lg:hidden fixed bottom-4 left-1/2 -translate-x-1/2 z-10">
                            <Sidebar />
                        </div>
                    </div>
                </div>

                {/* Ball */}
                <Circle position="left" />
                <Circle position="right" />
                <Circle position="top-right" />
            </div>
        </ScrollToTop>
    );
};

export default DefaultLayout;
