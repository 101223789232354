import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
import { toast } from 'react-toastify';

const Contact = () => {
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_27h1ub3',
        'template_szdtxwq',
        form.current,
        'quxGAOJKtMEWrVtWL'
      )
      .then(
        (result) => {
          console.log(result.text);
          toast.success('Send Success!');
        },
        (error) => {
          console.log(error.text);
          toast.error('Send Faild!');
        }
      );

    e.target.reset();
  };

  return (
    <div>
      <p className="text-title">Get in touch</p>
      <div className="block lg:grid grid-cols-2 pb-2">
        <div className="">
          <div className="mr-10">
            <p className="text-[20px] text-tab-text font-bold">
              Let's talk about everything!
            </p>
            <p className="text-[14px] mb-2 lg:mb-0 md:text-[15px] text-[#456]">
              👉 Don't like forms? Sent me an{' '}
              <a
                href="mailto:nam.np194336@gmail.com"
                className="text-blue-default hover:opacity-80"
                target="_blank"
                rel="noreferrer"
              >
                email
              </a>
              . Or contact with me in other{' '}
              <span
                className="underline cursor-pointer text-blue-default hover:opacity-80"
                onClick={handleScrollToTop}
              >
                media social
              </span>{' '}
              on top 👋!
            </p>
          </div>

          <div className="relative px-5 mb-4 hidden lg:block">
            <div className="absolute right-10 top-0 z-10 w-[300px] lg:w-[65%] xl:w-[300px]">
              <img
                src="./assets/images/other/astronaut.png"
                alt=""
                className="ml-auto w-full"
              />
              <img
                src="./assets/images/other/chat.png"
                alt=""
                className="w-[100px] lg:w-[150px] xl:w-[200px] absolute left-[-100px] xl:left-[-136px] top-[14px] z-10 rotate-[-10deg]"
              />
            </div>
            <img
              src="./assets/images/other/earth.png"
              alt=""
              className="w-[150px] lg:w-[180px] xl:w-[220px] absolute left-0 top-[190px] z-10 rotate-[18deg]"
            />
            <div className="mt-10 w-[80%] aspect-square bg-blue-gradient rounded-[70%_30%_75%_25%/49%_35%_65%_51%] blur-[30px]"></div>
          </div>
        </div>

        <form ref={form} onSubmit={sendEmail} className="">
          <div className="">
            <div className="mb-2">
              <label
                for="name"
                className="block mb-1 text-sm font-medium text-gray-90"
              >
                Your name
              </label>

              <input
                id="name"
                type="name"
                required
                className="outline-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 focus:border-blue-default"
                placeholder="Steve Job"
              />
            </div>
            <div className="mb-2">
              <label
                for="email"
                className="block mb-1 text-sm font-medium text-gray-90"
              >
                Your email
              </label>
              <input
                id="email"
                type="email"
                required
                className="!outline-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 focus:border-blue-default"
                placeholder="steve123@gmail.com"
              />
            </div>
            <div className="mb-2">
              <label
                for="message"
                className="block mb-1 text-sm font-medium text-gray-90"
              >
                Your message
              </label>
              <textarea
                name="message"
                id="message"
                required
                rows="7"
                placeholder="I want to talk to you..."
                className="outline-none resize-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:border-blue-default"
              ></textarea>
            </div>
            <button
              className="w-full rounded mt-2 py-2 text-sm text-white bg-blue-default shadow-lg hover:opacity-90 transition-all duration-200"
              type="submit"
            >
              Send Message
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
