export const projects = [
    {
        img: './assets/images/projects/sigma.png',
        title: 'Sigma DRM demo',
        content: 'Internship project at Thu Do Multimedia. Practice, configure webpack, typescript',
        technology: [
            './assets/images/skills/react.svg',
            './assets/images/skills/typescript.svg',
            './assets/images/skills/mui.svg',
            './assets/images/skills/redux.svg',
        ],
        liveDemo: 'https://sigma-react-type-mui.vercel.app/',
        github: 'https://gitlab.com/nblue151/sigma-react-type-mui',
    },
    {
        img: './assets/images/projects/iot.jpg',
        title: 'IOT mushrooms management',
        content:
            'Group project. The website has the following functions: login, registration, management of many mushroom houses, a mushroom house with many members. View real-time data, CURD intuitive sensor device .',
        technology: [
            './assets/images/skills/react.svg',
            './assets/images/skills/javascript.svg',
            './assets/images/skills/sass.svg',
            './assets/images/skills/mui.svg',
            './assets/images/skills/express.svg',
            './assets/images/skills/mongodb.svg',
        ],
        liveDemo: 'https://drive.google.com/file/d/1kEjZSzply53C2At88iBfk1XbqaV_QREk/view?usp=sharing',
        github: 'https://github.com/duyhacde/project_iot_20221',
    },
    {
        img: './assets/images/projects/zingmp3clone.png',
        title: 'Zing MP3 clone',
        content:
            'Zing MP3 web clone project, including the following functions: Login, register with google account, listen to music, forward music, rewind music, increase or decrease volume, save music or playlist to favorites list .',
        technology: [
            './assets/images/skills/react.svg',
            './assets/images/skills/javascript.svg',
            './assets/images/skills/tailwindcss.svg',
            './assets/images/skills/redux.svg',
            './assets/images/skills/express.svg',
            './assets/images/skills/mongodb.svg',
        ],
        liveDemo: 'https://zing-clone-151.vercel.app/',
        github: 'https://github.com/NBlue/react-music',
    },
    {
        img: './assets/images/projects/poplibrary.png',
        title: 'Pop Library',
        content:
            'Group project. This is a website that sells books; The project includes the following features: Login to register, search for books by name, author, view details, buy books',
        technology: [
            './assets/images/skills/react.svg',
            './assets/images/skills/javascript.svg',
            './assets/images/skills/sass.svg',
            './assets/images/skills/express.svg',
            './assets/images/skills/mongodb.svg',
        ],
        liveDemo: 'https://drive.google.com/file/d/1vtc03LuxbqubYBqh9sIu3M1a5mWV_q5H/view?usp=sharing',
        github: 'https://github.com/NBlue/PopLibrary',
    },
    {
        img: './assets/images/projects/mychat.png',
        title: 'My Chat',
        content:
            'Realtime firebase learning project includes features: login with google, add new chat and realtime chat',
        technology: [
            './assets/images/skills/nextjs.svg',
            './assets/images/skills/typescript.svg',
            './assets/images/skills/mui.svg',
            './assets/images/skills/firebase.svg',
        ],
        liveDemo: 'https://my-chat-pink.vercel.app/',
        github: 'https://github.com/NBlue/my-chat',
    },
];
