import './App.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './pages/Home';
import ErrorPage from './pages/ErrorPage';
import Experience from './pages/Experience';
import Contact from './pages/Contact';
import Interests from './pages/Interests';
import DefaultLayout from './Layouts/DefaultLayout';

import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect, useState } from 'react';
import Loading from './components/Loading';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

AOS.init({
  delay: 50,
  duration: 500,
  mirror: true,
});

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <DefaultLayout>
        <Home />
      </DefaultLayout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/experience',
    element: (
      <DefaultLayout>
        <Experience />
      </DefaultLayout>
    ),
  },
  {
    path: '/contact',
    element: (
      <DefaultLayout>
        <Contact />
      </DefaultLayout>
    ),
  },
  {
    path: '/interests',
    element: (
      <DefaultLayout>
        <Interests />
      </DefaultLayout>
    ),
  },
]);

function App() {
  const [isLoading, setIsLoading] = useState(true);

  console.log('Hello my friend!');

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <RouterProvider router={router} />
          <ToastContainer />
        </>
      )}
    </>
  );
}

export default App;
