import { quotes } from '../../data/quotes';
import Slider from 'react-slick';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { MdArrowForwardIos, MdArrowBackIosNew } from 'react-icons/md';
import './style.css';

const HandleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return <MdArrowForwardIos className={className} style={{ ...style }} onClick={onClick} />;
};

const HandlePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return <MdArrowBackIosNew className={className} style={{ ...style }} onClick={onClick} />;
};

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <HandleNextArrow />,
    prevArrow: <HandlePrevArrow />,
    responsive: [
        {
            breakpoint: 600,
            settings: {
                dots: false,
                arrows: false,
            },
        },
    ],
};

function useWindowSize() {
    const [sizeWidth, setSizeWidth] = useState(0);
    useLayoutEffect(() => {
        function updateSize() {
            setSizeWidth(window.innerWidth);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return sizeWidth;
}

const Quotes = () => {
    const parentRef = useRef(null);
    const quotesRef = useRef(null);

    const sizeWindow = useWindowSize();

    useEffect(() => {
        const width = parentRef.current.offsetWidth;
        quotesRef.current.style.width = `${width}px`;
    }, [sizeWindow]);

    return (
        <div className="w-full" ref={parentRef}>
            <div className="text-sub-title">My favorite quotes</div>

            {/* Nếu sử dụng slider trong 1 grid item thì nó bị phá vỡ css màn hình vì nó ko ăn width: 100% */}
            <div ref={quotesRef}>
                <Slider {...settings} className="">
                    {quotes.map((quote, index) => (
                        <div key={index} className="w-full h-full relative overflow-hidden rounded-[1rem]">
                            <div
                                className={'absolute top-0 left-0 w-full h-full z-[-1] blur-[40px]'}
                                style={{
                                    backgroundImage: `url(${quote.image})`,
                                    backgroundSize: `auto`,
                                    backgroundRepeat: `repeat`,
                                }}
                            ></div>

                            <div className="flex items-center h-[120px] p-2 md:p-4 lg:p-6 md:h-auto">
                                <img
                                    src={quote.image}
                                    alt=""
                                    className="w-[60px] md:w-[100px] lg:w-[120px] aspect-square rounded-[6px] mr-[20px]"
                                />
                                <div className="flex-1">
                                    <h3 className="text-[16px] md:text-[20px] font-bold text-white">{quote.name}</h3>
                                    <p className="text-[12px] lg:text-sm text-white mt-[6px]">"{quote.quote}"</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};

export default Quotes;
