import React from 'react';
import { NavLink } from 'react-router-dom';
import { HiOutlineHeart } from 'react-icons/hi';
import { BiBookContent, BiHomeAlt, BiChat } from 'react-icons/bi';

const Sidebar = () => {
    return (
        <div className="shadow-lg bg-white-bg rounded-[2rem] flex items-center gap-4 p-[12px_28px] lg:p-[30px] lg:border-none lg:grid lg:grid-cols-1 lg:gap-4 bg-filter">
            <NavLink to="/" className={({ isActive }) => (isActive ? 'tab-active' : 'tab')}>
                <img src="/assets/images/about_me.png" alt="" className="hidden lg:block lg:w-[75px] aspect-square" />
                <p className="hidden lg:block text-[16px] leading-[16px] text-tab-text uppercase font-bold">About Me</p>
                <BiHomeAlt className="text-lg m-[14px] block lg:hidden" />
            </NavLink>
            <NavLink to="/experience" className={({ isActive }) => (isActive ? 'tab-active' : 'tab')}>
                <img src="/assets/images/experience.png" alt="" className="hidden lg:block lg:w-[75px] aspect-square" />
                <p className="hidden lg:block text-[16px] leading-[16px] text-tab-text uppercase font-bold">
                    Experience
                </p>
                <BiBookContent className="text-lg  m-[14px] block lg:hidden" />
            </NavLink>
            <NavLink to="/contact" className={({ isActive }) => (isActive ? 'tab-active' : 'tab')}>
                <img src="/assets/images/contact.png" alt="" className="hidden lg:block lg:w-[75px] aspect-square" />
                <p className="hidden lg:block text-[16px] leading-[16px] text-tab-text uppercase font-bold">Contact</p>
                <BiChat className="text-lg m-[14px] block lg:hidden" />
            </NavLink>
            <NavLink to="/interests" className={({ isActive }) => (isActive ? 'tab-active' : 'tab')}>
                <img src="/assets/images/interests.png" alt="" className="hidden lg:block lg:w-[75px] aspect-square" />
                <p className="hidden lg:block text-[16px] leading-[16px] text-tab-text uppercase font-bold">
                    Interests
                </p>
                <HiOutlineHeart className="text-lg m-[14px] block lg:hidden" />
            </NavLink>
        </div>
    );
};

export default Sidebar;
