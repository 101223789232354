import React from 'react';

const Circle = ({ position = null }) => {
    return (
        <>
            {position === 'left' && (
                <div
                    className={`w-[14rem] h-[14rem] rounded-full bg-circle animate-bounce-l absolute top-[10%] left-[4%]`}
                ></div>
            )}
            {position === 'right' && (
                <div
                    className={`w-[20rem] h-[20rem] rounded-full bg-circle animate-bounce-r absolute top-[50%] right-[4%]`}
                ></div>
            )}
            {position === 'top-right' && (
                <div
                    className={`w-[5rem] h-[5rem] rounded-full bg-circle animate-bounce-r absolute top-[15%] right-[8%]`}
                ></div>
            )}
        </>
    );
};

export default Circle;
