export const marvelFilms = [
    {
        name: 'Guardians of the Galaxy',
        img: './assets/images/films/marvel/galaxy.jfif',
    },
    {
        name: 'Doctor Strange',
        img: './assets/images/films/marvel/doctor_strange.jfif',
    },
    {
        name: 'Avengers: Endgame',
        img: './assets/images/films/marvel/avengers_hoiket.jfif',
    },
    {
        name: 'Avengers: Infinity War',
        img: './assets/images/films/marvel/avengers_infinity_war.jfif',
    },
    {
        name: 'Black Panther',
        img: './assets/images/films/marvel/blackPanther.jfif',
    },
];

export const animeFilms = [
    {
        name: 'One Piece',
        img: './assets/images/films/anime/onepiece.jfif',
    },
    {
        name: 'Naruto',
        img: './assets/images/films/anime/naruto.jfif',
    },
    {
        name: 'Dragon Ball',
        img: './assets/images/films/anime/dragonball.jfif',
    },
    {
        name: 'Detective Conan',
        img: './assets/images/films/anime/conan.jfif',
    },
];
