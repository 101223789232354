import React, { useState } from 'react';

import ProjectItem from '../components/Project';
import Quotes from '../components/Quotes';
import { projects } from '../data/projects';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';

const Home = () => {
  const [seeMore, setSeeMore] = useState(false);

  return (
    <div>
      {/* <Helmet>
        <title>About Me</title>
        <meta name="description" content="My Information" />
        <meta name="keywords" content="seo" />
        <link rel="canonical" href="https://namvippro.io.vn/" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="About me" />
        <meta
          property="og:description"
          content="Hello! My name is Nam. Nice to meet you."
        />
        <meta
          property="og:image"
          content="https://namvippro.io.vn/assets/images/other/avatar5.jpg"
        />
        <meta property="og:url" content="https://namvippro.io.vn/" />
        <meta property="og:site_name" content="Namvippro" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="About me" />
        <meta
          name="twitter:description"
          content="Hello! My name is Nam. Nice to meet you."
        />
        <meta
          name="twitter:image"
          content="https://namvippro.io.vn/assets/images/other/avatar5.jpg"
        />
        <meta name="twitter:site" content="@your_twitter_handle" />
      </Helmet> */}

      {/* Introduct */}
      <div className="">
        <h1 className="text-title">Introduce</h1>

        <div className="grid grid-cols-1 sm:grid-cols-[1fr_2fr] lg:grid-cols-[1fr_3fr] gap-[20px] mx-5 mb-5 relative">
          <div className="relative">
            <div className="absolute bottom-0 left-1/2 -translate-x-1/2 w-[80%] aspect-square bg-blue-gradient rounded-[30%_70%_25%_75%/49%_35%_65%_51%] blur-[30px]"></div>
            <img
              src="./assets/images/other/peterthumb.png"
              alt=""
              className="relative z-1 w-[80%] translate-x-[-28%] sm:w-full sm:translate-x-0"
            />
          </div>
          <div
            data-aos="zoom-in-right"
            className="absolute right-0 flex items-center flex-col w-[60%] mt-5 p-3 rounded-[10px] border-2 border-white bg-[rgb(235,244,252)] shadow-sm h-fit sm:p-5 lg:p-[30px] sm:rounded-[20px] lg:rounded-[30px] sm:relative sm:block sm:w-full"
          >
            <p className="mb-0 text-center text-xs sm:text-sm sm:text-start lg:text-base sm:mb-1 lg:mb-[10px]">
              I am a quality-oriented software engineer who always wants to do
              right things and do things right. Hence, I can collaborate very
              well with teammates meanwhile working independently is one of my
              strong points.
            </p>
            <div
              //   href="./assets/file/CV.pdf"
              //   target="_blank"
              //   rel="noreferrer"
              onClick={() => toast.info('Comming Soon!')}
              className="mt-1 flex items-center justify-center text-white leading-[16px] bg-blue-gradient rounded-full w-fit shadow-md hover:shadow-btn-blue hover:translate-y-[-2px] transition-all duration-[1s] hover:text-animation text-xs px-5 py-[6px] sm:px-7 sm:py-2 sm:text-sm lg:text-base sm:mt-2 lg:mt-3"
            >
              <p className="">D</p>
              <p className="">o</p>
              <p className="">w</p>
              <p className="">n</p>
              <p className="">l</p>
              <p className="">o</p>
              <p className="">a</p>
              <p className="mr-1">d</p>
              <p className="">C</p>
              <p className="">V</p>
            </div>
            <div className="absolute top-[54px] left-[-7px] rotate-45 w-[10px] aspect-square border-2 border-white bg-[rgb(235,244,252)] border-t-transparent border-r-transparent sm:w-[15px] lg:w-[20px] sm:left-[-9px] lg:left-[-11px]"></div>
          </div>
        </div>
      </div>

      {/* Project */}
      <div className="mt-8">
        <div className="text-sub-title">My Projects</div>

        <ul className="px-0 lg:px-3">
          {projects.map((project, i) => (
            <ProjectItem key={i} project={project} reveser={i % 2 !== 0} />
          ))}
        </ul>

        {/* {!seeMore && (
                    <div data-aos="fade-up" className="text-center mb-10">
                        <button
                            onClick={() => setSeeMore(true)}
                            className="opacity-80 mx-auto px-5 py-1 rounded-full border-2 border-blue-default text-blue-default hover:scale-105 hover:opacity-80 hover:shadow-btn-blue transition-all duration-300"
                        >
                            See more
                        </button>
                    </div>
                )} */}
      </div>

      {/* Quotes */}
      <Quotes />
    </div>
  );
};

export default Home;
