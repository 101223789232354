import React from 'react';
import { skillsBackend, skillsFront } from '../data/skills';

const Experience = () => {
  return (
    <div>
      <h1 className="text-title mt-4">Experience</h1>

      <ul className="mx-2 border-l-2 border-[#ccc]">
        <li className="ml-5 mb-5">
          {/* Title */}
          <div className="relative mb-1">
            <p className="text-base text-secondary font-medium">
              5/2023 - Present
            </p>
            <a
              href="https://www.sotatek.com/"
              target="_blank"
              rel="noreferrer"
              className="text-[18px] md:text-[21px]  text-primary font-semibold"
            >
              Software Engineer - Sotatek
            </a>
            <div className="absolute top-1/2 -left-[29px] -translate-y-5">
              <div className="loader-experience delay-200"></div>
            </div>
          </div>

          {/* Content */}
          <div className="text-sm md:text-base text-primary pr-4">
            <p>
              I am currently a frontend developer for web and mobile
              applications, collaborating with my team on a variety of projects
              for clients worldwide:
            </p>
            <p className="">
              - Votee Lending: A Web3 NFT project with functionalities similar
              to OpenSea.
            </p>
            <p className="">
              - Awayday: A platform for doctors to preview schedules of
              conferences and seminars globally.
            </p>
            {/* <p className="">
              - NFT Ticket: A South Korean enterprise's project for buying and
              selling event tickets based on cryptocurrency (Web3).
            </p> */}
            <p className="">
              - Tierhub: An Australian government project utilizing blockchain
              technology to store information, enabling users to trace seafood
              data.
            </p>
          </div>
        </li>

        <li className="ml-5 mb-5">
          {/* Title */}
          <div className="relative mb-1">
            <p className="text-base text-secondary font-medium">
              6/2022 - 11/2022
            </p>
            <a
              href="https://thudomultimedia.vn/"
              target="_blank"
              rel="noreferrer"
              className="text-[18px] md:text-[21px]  text-primary font-semibold"
            >
              Frontend Web Intern - Thu Do Multimedia
            </a>
            <div className="absolute top-1/2 -left-[29px] -translate-y-5">
              <div className="loader-experience delay-200"></div>
            </div>
          </div>

          {/* Content */}
          <div className="text-sm md:text-base text-primary pr-4">
            <p>Trained in:</p>
            <p className="">
              - Soft skills: Communication, Presentation, Team-Work, Quality
              Awareness and Mindset, Problems Solving and Creative Thinking,
              Build Landing Page ReactJS.
            </p>
            <p className="">
              - Hard skills: Clean code, Design Pattern, Git Flow.
            </p>
          </div>
        </li>

        <li className="ml-5 mb-5">
          {/* Title */}
          <div className="relative">
            <p className="text-base text-secondary font-medium">
              9/2019 - 7/2024
            </p>
            <h3 className="text-[18px] md:text-[21px]  text-primary font-semibold">
              Ha Noi University Science Technology
            </h3>
            <div className="absolute top-1/2 -left-[29px] -translate-y-5">
              <div className="loader-experience"></div>
            </div>
          </div>

          {/* Content */}
          <div className="text-sm md:text-base text-primary pr-4">
            <p className="mb-1">Learn Computer Engineering</p>
            <p className="">
              - Team-worked on a lot of projects for 5 years with skills such as
              presentation, software designing, requirement analysis, and
              reporting.
            </p>
          </div>
        </li>
      </ul>

      <div className="">
        <p className="text-sub-title">My skills & Knowledge</p>

        <div className="border-2 border-[#fff] rounded-[20px] p-4">
          <div className="bg-[rgba(255,255,255,0.4)] px-0 md:px-[60px] py-[20px] rounded-[20px]">
            <h3 className="text-xl text-tab-text font-medium text-center mb-3">
              Frontend Development
            </h3>
            <ul className="flex items-center justify-center flex-wrap gap-3">
              {skillsFront.map((skill, index) => (
                <li key={index} className="w-[84px] flex items-center flex-col">
                  <img
                    src={skill.logo}
                    alt=""
                    className="h-[35px] md:h-[50px] hover:scale-110 transition-all duration-200"
                  />
                  <p className="text-base text-primary mt-2">{skill.name}</p>
                </li>
              ))}
            </ul>
          </div>

          <div className="mt-4 bg-[rgba(255,255,255,0.4)] px-0 md:px-[60px] py-[20px] rounded-[20px]">
            <h3 className="text-xl text-tab-text font-medium text-center mb-3">
              Backend Development
            </h3>
            <ul className="flex items-center justify-center flex-wrap gap-3">
              {skillsBackend.map((skill, index) => (
                <li key={index} className="w-[84px] flex items-center flex-col">
                  <img
                    src={skill.logo}
                    alt=""
                    className="h-[50px] hover:scale-110 transition-all duration-200"
                  />
                  <p className="text-base text-primary mt-2">{skill.name}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experience;
