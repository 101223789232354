export const skillsFront = [
  {
    name: 'HTML',
    logo: './assets/images/skills/html.svg',
  },
  {
    name: 'CSS',
    logo: './assets/images/skills/css.svg',
  },
  {
    name: 'Javascript',
    logo: './assets/images/skills/javascript.svg',
  },
  {
    name: 'Typescript',
    logo: './assets/images/skills/typescript.svg',
  },
  {
    name: 'ReactJS',
    logo: './assets/images/skills/react.svg',
  },
  {
    name: 'NextJS',
    logo: './assets/images/skills/nextjs.svg',
  },
  {
    name: 'R.Native',
    logo: './assets/images/skills/react-native.svg',
  },
  {
    name: 'Redux',
    logo: './assets/images/skills/redux.svg',
  },
  {
    name: 'Sass',
    logo: './assets/images/skills/sass.svg',
  },
  {
    name: 'Antd',
    logo: './assets/images/skills/antd.svg',
  },
  {
    name: 'Tailwindcss',
    logo: './assets/images/skills/tailwindcss.svg',
  },
  {
    name: 'Material UI',
    logo: './assets/images/skills/mui.svg',
  },
  {
    name: 'Boostrap',
    logo: './assets/images/skills/bootstrap.svg',
  },
];

export const skillsBackend = [
  {
    name: 'Nodejs',
    logo: './assets/images/skills/nodejs.svg',
  },
  {
    name: 'ExpressJS',
    logo: './assets/images/skills/express.svg',
  },
  {
    name: 'NestJS',
    logo: './assets/images/skills/nestjs.svg',
  },
  {
    name: 'MongoDB',
    logo: './assets/images/skills/mongodb.svg',
  },
  {
    name: 'My SQL',
    logo: './assets/images/skills/mysql.svg',
  },
  {
    name: 'Firebase',
    logo: './assets/images/skills/firebase.svg',
  },

  // {
  //     name: 'Git',
  //     logo: './assets/images/skills/git.svg',
  // },
];
