import React from 'react';
import { HiOutlineExternalLink } from 'react-icons/hi';
import { AiOutlineGithub } from 'react-icons/ai';

const ProjectItem = ({ project = {}, reveser = false }) => {
    return (
        <li data-aos="fade-up" className={`block lg:flex items-start gap-5 mb-10 ${reveser ? 'flex-row-reverse' : ''}`}>
            {/* Info */}
            <div className="flex-1">
                <div className="mr-0 flex items-center flex-col lg:block lg:mr-3">
                    <h1 className="text-[16px] font-semibold text-primary mt-3 mb-1 md:text-[18px] lg:text-[20px]">
                        {project.title}
                    </h1>
                    <h3 className=" font-normal text-tab-text text-[13px] text-center sm:text-start md:text-[14px] lg:text-[16px]">
                        {project.content}
                    </h3>
                    <ul className="flex items-center gap-3 mt-2">
                        {project.technology.map((techImg, index) => (
                            <li className="" key={index}>
                                <img src={techImg} alt="" className="h-6" />
                            </li>
                        ))}
                    </ul>
                    <div className="flex items-center gap-5 mt-2 mb-2 md:mt-4 lg:mb-0 lg:mt-6">
                        <a
                            href={project.liveDemo}
                            target="_blank"
                            rel="noreferrer"
                            className="flex items-center bg-blue-default text-sm leading-[14px] text-white px-3 py-2 rounded shadow-md hover:opacity-90 hover:shadow-btn-blue transition-all duration-300"
                        >
                            <HiOutlineExternalLink className="text-[20px] mr-1" />
                            Live Demo
                        </a>
                        <a
                            href={project.github}
                            target="_blank"
                            rel="noreferrer"
                            className="flex items-center bg-primary text-sm leading-[14px] text-white px-3 py-2 rounded shadow-md hover:opacity-90 hover:shadow-btn-dark transition-all duration-300"
                        >
                            <AiOutlineGithub className="text-[20px] mr-1" />
                            Github
                        </a>
                    </div>
                </div>
            </div>

            {/* Image */}
            <a href={project.liveDemo} target="_blank" rel="noreferrer" className="flex-1">
                <div className=" border-2 border-[#999] rounded-3xl bg-white box-border">
                    <div className="m-2 aspect-video rounded-2xl overflow-hidden">
                        <img
                            src={project.img}
                            alt=""
                            className="h-full object-cover hover:scale-110 transition-all duration-300"
                        />
                    </div>
                </div>
            </a>
        </li>
    );
};

export default ProjectItem;
