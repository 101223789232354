import Slider from 'react-slick';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { MdArrowForwardIos, MdArrowBackIosNew } from 'react-icons/md';
import './style.css';

const HandleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <MdArrowForwardIos
      className={className}
      style={{ ...style }}
      onClick={onClick}
    />
  );
};

const HandlePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <MdArrowBackIosNew
      className={className}
      style={{ ...style }}
      onClick={onClick}
    />
  );
};

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  nextArrow: <HandleNextArrow />,
  prevArrow: <HandlePrevArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
        arrows: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
      },
    },
  ],
};

function useWindowSize() {
  const [sizeWidth, setSizeWidth] = useState(0);

  useLayoutEffect(() => {
    function updateSize() {
      setSizeWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return sizeWidth;
}

const Films = ({ films }) => {
  const parentRef = useRef(null);
  const quotesRef = useRef(null);

  const sizeWindow = useWindowSize();

  useEffect(() => {
    const width = parentRef.current.offsetWidth;
    quotesRef.current.style.width = `${width}px`;
  }, [sizeWindow]);

  return (
    <div className="w-full" ref={parentRef}>
      <div ref={quotesRef}>
        <Slider {...settings} className="">
          {films.map((film, index) => (
            <li key={index} className="p-3">
              <div className="relative shadow-md item-hover">
                <img
                  src={film.img}
                  alt=""
                  className="w-full aspect-[2/3] rounded-md"
                />
                <div className="absolute top-0 left-0 w-full h-full bg-[rgba(255,255,255,0.7)] rounded-md flex flex-col items-center justify-center opacity-0 transition-all duration-500">
                  <h6 className="text-[19px] text-primary font-semibold mb-5">
                    {film.name}
                  </h6>
                  <a
                    href="/"
                    className="bg-blue-default text-sm leading-[14px] text-white px-3 py-2 rounded shadow-md hover:opacity-90 hover:shadow-btn-blue transition-all duration-300"
                  >
                    See more
                  </a>
                </div>
              </div>
            </li>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Films;
