import React from 'react';
import './styls.css';

const Loading = () => {
  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <div className="dot-flasing">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Loading;
