import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Helmet } from 'react-helmet';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <Helmet>
      <title>Information</title>
      <meta name="description" content="My Information" />
      <meta name="keywords" content="seo" />
      <link rel="canonical" href="https://namvippro.io.vn/" />

      Open Graph Meta Tags (Facebook, LinkedIn)
      <meta property="og:type" content="website" />
      <meta property="og:title" content="About me" />
      <meta
        property="og:description"
        content="Hello! My name is Nam. Nice to meet you."
      />
      <meta
        property="og:image"
        content="https://namvippro.io.vn/assets/images/other/avatar5.jpg"
      />
      <meta property="og:url" content="https://namvippro.io.vn/" />
      <meta property="og:site_name" content="Namvippro" />

      Twitter Card Meta Tags (Optional)
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="About me" />
      <meta
        name="twitter:description"
        content="Hello! My name is Nam. Nice to meet you."
      />
      <meta
        name="twitter:image"
        content="https://namvippro.io.vn/assets/images/other/avatar5.jpg"
      />
      <meta name="twitter:site" content="@your_twitter_handle" />
    </Helmet> */}
    <App />
  </React.StrictMode>
);
