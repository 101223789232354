import React from 'react';
import Films from '../components/Film';
import { animeFilms, marvelFilms } from '../data/films';

const Interests = () => {
  return (
    <div>
      <div className="text-title">My Interests</div>
      <div className="">
        <div className="flex items-center mb-2">
          <img src="./assets/images/other/game.png" alt="" className="h-10" />
          <h2 className="text-[22px] text-primary font-semibold">Game</h2>
        </div>
        <div className="">
          <div className="block sm:flex items-start gap-4 p-5 rounded-[1rem] bg-white mb-4 shadow-lg hover:scale-[1.001] hover:shadow-2xl transition-all duration-300">
            <img
              src="./assets/images/other/genshin.webp"
              alt=""
              className="w-full sm:w-auto sm:h-[150px] aspect-video rounded-xl"
            />
            <div className="mt-2 sm:mt-0">
              <h6 className="text-[19px] text-primary font-semibold">
                🎮 Genshin Impact
              </h6>
              <p className="text-base text-primary font-normal">
                Connect and play with me by UID:{' '}
                <span className="text-[#ed1d25]">291565987</span> ✋
              </p>
            </div>
          </div>
          <div className="block sm:flex items-start gap-4 p-5 rounded-[1rem] bg-white mb-4 shadow-lg hover:scale-[1.001] hover:shadow-2xl transition-all duration-300">
            <img
              src="./assets/images/other/lienquan.jpg"
              alt=""
              className="w-full sm:w-auto sm:h-[150px] aspect-video rounded-xl"
            />
            <div className="mt-2 sm:mt-0">
              <h6 className="text-[19px] text-primary font-semibold">
                🎮 Arena of Valor
              </h6>
              <p className="text-base text-primary font-normal">
                Connect and play with me by{' '}
                <a
                  href="https://www.facebook.com/profile.php?id=100010348932056"
                  target="_blank"
                  rel="noreferrer"
                  className="text-facebook1 hover:underline"
                >
                  facebook
                </a>{' '}
                or nickgame:{' '}
                <span className="text-[#ed1d25]">Giảitríthôi=_=</span> ✋
              </p>
            </div>
          </div>
          <div className="block sm:flex items-start gap-4 p-5 rounded-[1rem] bg-white mb-4 shadow-lg hover:scale-[1.001] hover:shadow-2xl transition-all duration-300">
            <img
              src="./assets/images/other/vgvd.jpg"
              alt=""
              className="w-full sm:w-auto sm:h-[150px] aspect-video rounded-xl"
            />
            <div className="mt-2 sm:mt-0">
              <h6 className="text-[19px] text-primary font-semibold">
                🎮 Honor of Kings
              </h6>
              <p className="text-base text-primary font-normal">
                Connect and play with me by{' '}
                <span className="text-[#4abc83]">wechat</span>:
              </p>
              <img
                src="./assets/images/other/qrwechat.jpg"
                alt=""
                className="h-[80px] aspect-square object-cover mt-1"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-8">
        <div className="flex items-center mb-2">
          <img src="./assets/images/other/film.png" alt="" className="h-10" />
          <h2 className="text-[22px] text-primary font-semibold">Film</h2>
        </div>
        <div className="mb-4">
          <div className="flex justify-center">
            <p className="text-xl font-semibold text-primary relative before:absolute before:bottom-0 before:left-1/2 before:-translate-x-1/2 before:h-[3px] before:w-1/2 before:rounded-md before:bg-tab-active">
              Marvel movies
            </p>
          </div>

          <Films films={marvelFilms} />
        </div>
        <div className="">
          <div className="flex justify-center">
            <p className="text-xl font-semibold text-primary relative before:absolute before:bottom-0 before:left-1/2 before:-translate-x-1/2 before:h-[3px] before:w-1/2 before:rounded-md before:bg-tab-active">
              Animes
            </p>
          </div>

          <Films films={animeFilms} />
        </div>
      </div>
    </div>
  );
};

export default Interests;
